import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import styles from "./SideVideo.module.css";

const SideVideo: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });
  return (
    <motion.div
      className={styles.sideVideoContainer}
      ref={ref}
      variants={{
        hidden: { opacity: 0, x: -200 },
        visible: { opacity: 1, x: 0 },
      }}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      transition={{ duration: 0.75, delay: 0.50 }}
    >
      <div className={styles.videoContainer}>
        <iframe
          className={styles.video}
          src="https://www.youtube.com/embed/Bm_fAY4__XY"
          title="SageTech"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
      </div>
      <div className={styles.textContainer}>
        <p className={styles.text}>
          Check out <b>SageTech</b> team members Claudia Santacruz, Oliver
          Kisza, and Brianna Lischy on Nasdaq TradeTalks! Tune in as they
          discuss with market reporter Jill Malandrino the company’s mission,
          EdTech, and VR market potential.
        </p>
      </div>
    </motion.div>
  );
};

export default SideVideo;
