import React from "react";
import styles from "./HomeScreen.module.css";
import GlassCardContainer from "../../components/GlassCardContainer";
import SideVideo from "../../components/SideVideo";
import AnimatedLinkedIn from "../../components/AnimatedLinkedIn";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  ZoomIn,
  FadeIn,
} from "react-scroll-motion";
import LearningFact from "../../components/LearningFact";
import MainHeader from "../../components/UI/MainHeader";
import Footer from "../../components/UI/Footer";

const HomeFullScreen: React.FC = () => {
  const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());

  return (
    <div className={styles.main}>
      <ScrollContainer style={{ backgroundColor: "#00074D", color: "white" }}>
        <MainHeader />
        <GlassCardContainer />
        <div className={styles.videoSideBarContainer}>
          <SideVideo />
        </div>
        <ScrollPage>
          <Animator animation={ZoomInScrollOut}>
            <LearningFact
              text="QUICK STUDY ON VR CONSUMER BEHAVIOR ✨"
              size={30}
            />
          </Animator>
        </ScrollPage>

        <ScrollPage>
          <div className={styles.starBackground}>
            <Animator animation={batch(MoveIn(), Sticky(), MoveOut(0, -50))}>
              <LearningFact
                text="Students are up to 275% more confident when acting on what they learned during XR lessons"
                size={20}
              />
            </Animator>
          </div>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(MoveIn(), StickyIn(), MoveOut(0, -50))}>
            <LearningFact
              text="93% of teachers feel VR tech would bring excitement to their students in the classroom"
              size={20}
            />
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(Fade(), Sticky())}>
            <div className={styles.linkedinContainer}>
              <AnimatedLinkedIn
                title="SageTech at IRT"
                body="Interdisciplinary Research Team (IRT) symposium"
                link="https://www.linkedin.com/feed/update/urn:li:activity:7058138267547791360/"
              />
              <AnimatedLinkedIn
                title="XR Market"
                body="The XR market is expected to accelerate towards a value of around $300 billion by 2024 alone, according to XRToday.com."
                link="https://www.linkedin.com/feed/update/urn:li:activity:6998373338712567808/"
              />
              <AnimatedLinkedIn
                title="The Modern Leaner"
                body="Tune into our podcast called The Modern Leaner! Rutgers students Brianna, Claudia, and Rut talk about the neuroscience of learning, EdTech, and the overall inspiration for SageTech!"
                link="https://www.linkedin.com/posts/sagetech-life_podcast-edtech-neuroscience-activity-6993932469079023616-xHzs?utm_source=share&utm_medium=member_desktop"
              />
            </div>
          </Animator>
        </ScrollPage>
        <Footer />
      </ScrollContainer>
    </div>
  );
};

export default HomeFullScreen;
