import React, { useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import Footer from "../components/UI/Footer";
import MWdemo from "../assets/Metaweaversimulation.jpeg";
import metavideo from "../assets/metaviewer.mp4";
import XRdemo from "../assets/XRexpo.jpg";
import styles from "./MetaWeaver.module.css";
import SlideInCards from "../components/SlideInCards";
import VRobjects from "../assets/VRObjects.jpeg";
import Sagedemo from "../assets/VRSagedemo.jpeg"
interface MetaScreenProps {
  activePath: any;
}

interface GlassContainerProps {
  title: string;
  text: string;
  image: string;
  flex?: "row" | "row-reverse" | "column" | "column-reverse";
}

const metaWeaverContent: GlassContainerProps[] = [
  {
    title: "",
    text: "MetaWeaver is our software for educators to unlock the full potential of virtual reality, creating captivating and interactive learning experiences. Designed with utmost attention to user-friendly functionality, MetaWeaver empowers teachers to seamlessly translate their lessons into captivating virtual environments.",
    image:
      MWdemo,
    flex: "row",
  },
  {
    title: "Intuitively designed, easy to use.",
    text: "Unlike conventional approaches that rely on clunky 2-dimensional interfaces, MetaWeaver revolutionizes the creation process by offering a more natural and intuitive workflow. By harnessing the power of generative AI, hand gestures, and voice commands MetaWeaver empowers educators to unleash their creativity and effortlessly conjure immersive 3D worlds that enhance their students’ educational journey.",
    image:
      XRdemo,
    flex: "row-reverse",
  },
  {
    title: "Object Library",
    text: "MetaWeaver boasts an extensive open source object library, fostering a collaborative ecosystem where creators can share objects, environment templates, and textures. Collaboration among educators simplifies the design process, improving the quality of everyone’s lessons and ensuring that teachers have the resources to create the best virtual learning environments possible.",
    image:
      VRobjects,
    flex: "row",
  },
  {
    title: "",
    text: "Combined with the Sage platform, MetaWeaver transforms any teachable topic into a visually stunning and immersive experience that can be accessed by anyone in the world. Whether it’s exploring historical landmarks, diving into the depths of the ocean, or dissecting complex scientific concepts, MetaWeaver empowers educators to craft captivating lessons that engage students on a whole new level, revitalizing learning in the classroom with limitless potential.",
    image:
      Sagedemo,
    flex: "row-reverse",
  },
];

const MetaWeaverScreen: React.FC<MetaScreenProps> = ({ activePath }) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const firstRender = useRef(true);
  const location = useLocation();

  useEffect(() => {
    activePath(String(location.pathname));
    firstRender.current = false;
  }, [location.pathname, activePath]);

  return (
    <>
      <div className={styles.mainContainer}>
        <video
          className={styles.bgVideo}
          width="100%"
          src={metavideo}
          autoPlay
          loop
          muted
        >
          <source src={metavideo} type="video/mp4" />
        </video>
        <div style={{ marginTop: 130 }}>&nbsp;</div>
        <motion.div
          className={styles.headerContainer}
          variants={{
            hidden: { opacity: 0, scale: 0 },
            visible: { opacity: 1, scale: 1},
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 1.25 }}
        >
          <h1 className={styles.headerTitle}>MetaWeaver</h1>
        </motion.div>
        {metaWeaverContent.map((item) => (
          <SlideInCards
            title={item.title}
            text={item.text}
            image={item.image}
            flex={windowSize.current[0] < 721 ? "column" : item.flex}
          />
        ))}
      </div>
      <Footer />
    </>
  );
};

export default MetaWeaverScreen;
