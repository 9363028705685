import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";

import styles from "./HomeScreen.module.css";
import GlassCardContainer from "../../components/GlassCardContainer";
import SideVideo from "../../components/SideVideo";
import AnimatedLinkedIn from "../../components/AnimatedLinkedIn";
import LearningFact from "../../components/LearningFact";
import MainHeader from "../../components/UI/MainHeader";
import Footer from "../../components/UI/Footer";

const HomeMobileScreen: React.FC = () => {
  const refFaq = useRef<HTMLDivElement>(null);
  const refLink = useRef<HTMLDivElement>(null);
  const isInViewFaq = useInView(refFaq, { once: true });
  const isInViewLink = useInView(refLink, { once: true });

  return (
    <div className={styles.main}>
      <MainHeader />
      <GlassCardContainer />
      <div className={styles.videoSideBarContainer}>
        <SideVideo />
      </div>
      <div className={styles.mobileStart} ref={refFaq}>
        <motion.div
          variants={{
            hidden: { opacity: 0, x: -400 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate={isInViewFaq ? "visible" : "hidden"}
          transition={{ duration: 1, delay: 0.15 }}
        >
          <LearningFact
            text="QUICK STUDY ON VR CONSUMER BEHAVIOR ✨"
            size={30}
          />
        </motion.div>
        <motion.div
          variants={{
            hidden: { opacity: 0, x: 400 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate={isInViewFaq ? "visible" : "hidden"}
          transition={{ duration: 1, delay: 0.25 }}
        >
          <LearningFact
            text="Students are up to 275% more confident when acting on what they learned during XR lessons"
            size={20}
          />
        </motion.div>
        <motion.div
          variants={{
            hidden: { opacity: 0, x: -400 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate={isInViewFaq ? "visible" : "hidden"}
          transition={{ duration: 1, delay: 0.35 }}
        >
          <LearningFact
            text="93% of teachers feel VR tech would bring excitement to their students in the classroom"
            size={20}
          />
        </motion.div>
        <motion.div
          className={styles.linkedinContainer}
          ref={refLink}
          variants={{
            hidden: { opacity: 0, y: 400 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={isInViewLink ? "visible" : "hidden"}
          transition={{ duration: 1, delay: 0.15 }}
        >
          <AnimatedLinkedIn
            title="SageTech at IRT"
            body="Interdisciplinary Research Team (IRT) symposium"
            link="https://www.linkedin.com/feed/update/urn:li:activity:7058138267547791360/"
          />
          <AnimatedLinkedIn
            title="XR Market"
            body="The XR market is expected to accelerate towards a value of around $300 billion by 2024 alone, according to XRToday.com."
            link="https://www.linkedin.com/feed/update/urn:li:activity:6998373338712567808/"
          />
          <AnimatedLinkedIn
            title="The Modern Leaner"
            body="Tune into our podcast called The Modern Leaner! Rutgers students Brianna, Claudia, and Rut talk about the neuroscience of learning, EdTech, and the overall inspiration for SageTech!"
            link="https://www.linkedin.com/posts/sagetech-life_podcast-edtech-neuroscience-activity-6993932469079023616-xHzs?utm_source=share&utm_medium=member_desktop"
          />
        </motion.div>
      </div>
      <div style={{ backgroundColor: "#00074D" }}>
        <Footer />
      </div>
    </div>
  );
};

export default HomeMobileScreen;
