import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import styles from "./SlideInCards.module.css";

interface GlassContainerProps {
  title: string;
  text: string;
  image: string;
  flex?: "row" | "row-reverse" | "column" | "column-reverse";
}

const SlideInCards: React.FC<GlassContainerProps> = ({
  title,
  text,
  image,
  flex,
}) => {
  const ref = useRef<any>(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      ref={ref}
      className={styles.HContainer}
      style={{ flexDirection: flex }}
    >
      <motion.div
        className={styles.textContainer}
        variants={{
          hidden:
            flex === "row" ? { opacity: 0, x: -400 } : { opacity: 0, x: 400 },
          visible: { opacity: 1, x: 0 },
        }}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        transition={{ duration: 0.75, delay: 0.25 }}
        style={flex === "row" ? { textAlign: "right" } : { textAlign: "left" }}
      >
        <h1 className={styles.textMargin} style={{fontSize: "1.7rem"}}>{title}</h1>
        <p className={styles.textMargin}>{text}</p>
      </motion.div>
      <motion.div
        className={styles.imgContainer}
        variants={{
          hidden:
            flex === "row" ? { opacity: 0, x: 400 } : { opacity: 0, x: -400 },
          visible: { opacity: 1, x: 0 },
        }}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        transition={{ duration: 0.75, delay: 0.25 }}
      >
        <img src={image} alt={title} width="100%" />
      </motion.div>
    </div>
  );
};

export default SlideInCards;
