import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";

import logoShort from "../../assets/logoShort.png";
import SocialMediaButton from "../SocialMediaButton";

const Footer: React.FC = () => {
  const navigateToInstagram = () => {
    window.location.href = "https://www.instagram.com/Sage.Tech/?hl=en";
  }

  const navigateToLinkedin = () => {
    window.location.href = "https://www.linkedin.com/company/sagetech-life/";
  }

  return (
    <footer className={styles.footerContainer}>
      <div className={styles.mainContainer}>
        <img src={logoShort} alt="SageTech" className={styles.footerLogo} />
        <div className={styles.VContainerNavLink}>
            {/* <span className={styles.formLabel}>Subscribe to our Newsletter</span> */}
          {/* <form>
            <input type="email" placeholder="Enter you Email"/>
            <button>Subscribe</button>
          </form> */}
          <ul className={styles.footerNavBarContainer}>
            <Link to="/" className={styles.navLink}>
              <li>Home</li>
            </Link>
            <Link to="/metaweaver" className={styles.navLink}>
              <li>MetaWeaver</li>
            </Link>
            <Link to="/sage" className={styles.navLink}>
              <li>Sage</li>
            </Link>
            <Link to="/science" className={styles.navLink}>
              <li>Science</li>
            </Link>
            <Link to="/about" className={styles.navLink}>
              <li>About</li>
            </Link>
          </ul>
        </div>
        <div className={styles.socialMediaContainer}>
          <SocialMediaButton onPress={navigateToInstagram}>
            <i
              className="uil uil-instagram-alt"
              style={{ fontSize: 30, padding: 1 }}
            ></i>
          </SocialMediaButton>
          <SocialMediaButton onPress={navigateToLinkedin}>
            <i
              className="uil uil-linkedin"
              style={{ fontSize: 30, padding: 1 }}
            ></i>
          </SocialMediaButton>
        </div>
      </div>
      <div className={styles.copyrightContainer}>
        <span>© 2023 SageTech</span>
      </div>
    </footer>
  );
};

export default Footer;
