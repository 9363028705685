import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./ScienceScreen.module.css";
import SageScience1 from "../assets/SageScience1.png";
import Tilt from "react-parallax-tilt";
import SageScience3 from "../assets/SageScience3.png";
import SageScience4 from "../assets/SageScience4.png";
import Footer from "../components/UI/Footer";
import { motion, useInView } from "framer-motion";

interface ScienceScreenProps {
  activePath: any;
}



const ScienceScreen: React.FC<ScienceScreenProps> = ({ activePath }) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const firstRender = useRef(true);
  const location = useLocation();

  useEffect(() => {
    activePath(String(location.pathname));
    firstRender.current = false;
  }, [location.pathname, activePath]);

  const VRHeaderRef = useRef<HTMLDivElement>(null);
  const VRHeaderIsInView = useInView(VRHeaderRef, { once: false });

  const VRBodyRef = useRef<HTMLDivElement>(null);
  const VRBodyIsInView = useInView(VRBodyRef, { once: false });

  const personalizationHeaderRef = useRef<HTMLDivElement>(null);
  const personalizationHeaderIsInView = useInView(personalizationHeaderRef, { once: false });

  const personalizationBodyRef = useRef<HTMLDivElement>(null);
  const personalizationBodyIsInView = useInView(personalizationBodyRef, { once: false });

  const personalizationImagesRef = useRef<HTMLDivElement>(null);
  // const personalizationImagesIsInView = useInView(personalizationImagesRef, { once: false });

  return (
    <>
      <div className={styles.main1}>
        {/* Experiential Learning Intro */}
        <div className={styles.experimentalLearningContainer}>
          <div className={styles.experimentalLearningTextCol}>
            <motion.div
              className={styles.experimentalLearningHeaderDiv}
              variants={{
                hidden: { opacity: 0, scale: 0 },
                visible: { opacity: 1, scale: 1},
              }}
              initial="hidden"
              animate={"visible"}
              transition={{ duration: 1, delay: 0.25 }}
            >
              <p className={styles.experimentalLearningHeader}>
                Why Experiential Learning?
              </p>
            </motion.div>

            <motion.div
              className={styles.kolb}
              variants={{
                hidden: { opacity: 1, x: windowSize.current[0] > 721 ? -800 : 0 },
                visible: { opacity: 1, x: 0},
              }}
              initial="hidden"
              animate={"visible"}
              transition={{ duration: 1, delay: 0.25 }}
            >
              <p>
                Experiential learning, or <b className={styles.kolbBold}>learning by doing</b>, has been proven as the most effective educational approach, emphasizing practical engagement.
              </p>
              <p>
                SageTech takes inspiration from David Kolb’s Experiential Learning model, which introduced a cyclical four-step process of transformative experience. His cycle is pictured to the right<b className={styles.kolbBold}> ➝ </b>
              </p>
              <p className={styles.kolbBold}>
                ❝Learning is the process whereby knowledge is created through
                the transformation of experience.❞ - David Kolb
              </p>
              <p>
                Supported in depth in our literature review, we found experiential learning activities increase acceptance and retention of new concepts, as well as heighten attention and engagement.
              </p>
            </motion.div>
          </div>

          <motion.div
            className={styles.experimentalLearningImagesCol}
            variants={{
              hidden: { opacity: 0, scale: 0 },
              visible: { opacity: 1, scale: 1, rotate: 360}
            }}
            initial="hidden"
            animate={"visible"}
            transition={{ duration: 1, delay: 0.25 }}
          >
            <Tilt>
            <img
              className={styles.SageScience1}
              src={SageScience1}
              alt="Kolb Diagram"
            />
            </Tilt>
          </motion.div>
        </div>
      </div>

      {/* Why Virtual Reality? */}
      <div className={styles.VRContainer}>
        <motion.div
              className={styles.VRHeader}
              variants={{
                hidden: { opacity: 0, scale: 0 },
                visible: { opacity: 1, scale: 1},
              }}
              initial="hidden"
              animate={VRHeaderIsInView ? "visible" : "hidden"}
              transition={{ duration: 1, delay: 0.25 }}
              ref={VRHeaderRef}
        >
          <p className={styles.marginGone}>Why Virtual Reality?</p>
        </motion.div>
        <motion.div
              className={styles.VRBody}
              variants={{
                hidden: { opacity: 0, x: windowSize.current[0] > 721 ? -1000 : 0 },
                visible: { opacity: 1, x: 0},
              }}
              initial="hidden"
              animate={VRBodyIsInView ? "visible" : "hidden"}
              transition={{ duration: 1, delay: 0.25 }}
              ref={VRBodyRef}
        >
          <p>
            At SageTech, our focus on VR offers immersive 3D environments, engaging with concepts otherwise unfeasible in traditional classrooms.
          </p>
          <p>
            Imagine venturing inside a plant cell during a biology class or visualizing mathematical concepts in real-life scenarios. VR enables macro and micro-scale exploration, enhancing conceptual comprehension.
          </p>
          <p>
            <b className={styles.VRBold}>
            In a study by PwC, learners using VR demonstrated <u className={styles.VRu}>four times</u> the focus, 3.75 times the emotional connection to the content, and 275% more confidence in applying what they learned through immersive experiences.
            </b>
          </p>
          <p>
            Through VR goggles and sensor-equipped devices, rudimentary <b className={styles.VRBold}>models of students’ attention</b> can be generated for the first time. SageTech’s companion app aims to clarify areas students are struggling or excelling in, increasing <b className={styles.VRBold}>visibility</b> for educators.
          </p>
          <p>
            VR also provides access to experiences that individuals with disabilities may otherwise not have access to, serves as an alternative to resource-constrained experiences, and enables repeated experiences that promote deep learning.
          </p>
          <p>
            <b className={styles.VRBold}>
              We envision VR as a means to level the educational playing field and enhance educational impact in schools.
            </b>
          </p>
        </motion.div>
      </div>

      {/* Why personalization */}
      <div className={styles.personalization}>
        <div className={styles.personalizationContainer}>
          { <motion.div
              className={styles.personalizationImagesCol}
              variants={{
                hidden: { opacity: 0, x: windowSize.current[0] > 721 ? -700 : 0, y: 0},
                visible: { opacity: 1, x: 0, y:0}
              }}
              initial="hidden"
              animate={"visible"}
              transition={{ duration: 1, delay: 0.25 }}
              ref={personalizationImagesRef}
          >
            <Tilt>
            <img
              className={styles.SageScience3}
              src={SageScience3}
              alt="VR Learning"
            />
            <img
              className={styles.SageScience4}
              src={SageScience4}
              alt="VR Learning"
            />
            </Tilt>
          </motion.div> }

          <div className={styles.personalizationTextCol}>
              <motion.div
                className={styles.personalizationHeaderDiv}
                variants={{
                  hidden: { opacity: 0, scale: 0 },
                  visible: { opacity: 1, scale: 1}
                }}
                initial="hidden"
                animate={personalizationHeaderIsInView ? "visible" : "hidden"}
                transition={{ duration: 1, delay: 0.25 }}
                ref={personalizationHeaderRef}
              >
                <p className={styles.personalizationHeader}>
                  Why Personalization?
                </p>
            </motion.div>

            <motion.div
                className={styles.personalizationBody}
                variants={{
                  hidden: { opacity: 0, x: windowSize.current[0] > 721 ? 300 : 0 },
                  visible: { opacity: 1, x: 0}
                }}
                initial="hidden"
                animate={personalizationBodyIsInView ? "visible" : "hidden"}
                transition={{ duration: 1, delay: 0.25 }}
                ref={personalizationBodyRef}
            >
              <p>
                Personalization, or tailoring to the unique habits and preferences of each student, plays a crucial role in education. While personalization is essential, its implementation has been limited within classrooms.
              </p>
              <p>
                Sage makes personalization easier by continuously updating each student’s profile with data on their learning style and interests. Leveraging student data within our machine learning model maximizes student attention and focus, enhancing overall lesson efficiency.
              </p>
            </motion.div>
          </div>
        </div>
      </div>

      <div className={styles.ongoingResearchContainer}>
        <div className={styles.researchHeader}>Ongoing Research</div>

        <div className={styles.researchBody}>
          Our team is currently working on a whitepaper for our student model of
          attention with the guidance of Rutgers University’s Innovation,
          Design, and Entrepreneurship Academy (IDEA) and its staff.
        </div>

        <iframe
          className={styles.spotify}
          src="https://open.spotify.com/embed/show/1o8IjvAA0oOYKM3AKz2Qex?utm_source=generator&theme=0"
          width="50%"
          height="152"
          allow="autoplay; clipboard-write; encrypted-media; 
        fullscreen; picture-in-picture"
          loading="lazy"
          title="Sagetech Spotify"
        ></iframe>
      </div>
      <div className={styles.footer}>
         <Footer />
      </div>
      
    </>
  );
};

export default ScienceScreen;
