import React from "react";
import { Card, Grid, Text, Link } from "@nextui-org/react";
import logoShort from "../assets/logoShort.png"

interface AnimatedLinkedInProps {
  title: string;
  body: string;
  link: string;
}

const AnimatedLinkedIn: React.FC<AnimatedLinkedInProps> = ({ title, body, link }) => {
  return (
    <Card css={{ p: "$6", mw: "350px", borderTop: "6px solid #F000D3", width: 400 }}>
      <Card.Header>
        <img
          alt="SageTech"
          src={logoShort}
          width="42px"
          style={{borderRadius: 50, backgroundColor: '#000', padding: "5px 23px"}}
        />
        <Grid.Container css={{ pl: "$6" }}>
          <Grid xs={12}>
            <Text h4 css={{ lineHeight: "$xs" }}>
            {title}
            </Text>
          </Grid>
        </Grid.Container>
      </Card.Header>
      <Card.Body css={{ py: "$2" }}>
        <Text css={{marginRight: 4}}>
          {body}
        </Text>
      </Card.Body>
      <Card.Footer>
        <Link
          color="primary"
          target="_blank"
          href={link}
        >
          Visit Post on LinkedIn.
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default AnimatedLinkedIn;