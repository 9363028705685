import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HomeMobileScreen from "./HomeScreen/HomeMobileScreen";
import HomeFullScreen from "./HomeScreen/HomeFullScreen";

interface HomeScreenProps {
  activePath: any;
}

const HomeScreen: React.FC<HomeScreenProps> = ({ activePath }) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const firstRender = useRef(true);
  const location = useLocation();

  useEffect(() => {
    activePath(String(location.pathname));
    firstRender.current = false;
  }, [location.pathname, activePath]);

  if (windowSize.current[0] < 721) {
    return <HomeMobileScreen />
  } else {
    return <HomeFullScreen />
  }
};

export default HomeScreen;
