import React, { useRef } from "react";
import { useNavigate } from 'react-router';
import { motion, useInView } from "framer-motion";
import Tilt from "react-parallax-tilt";
import vr from "../../assets/vr.png";

import SliderButton from "../SliderButton";
import styles from "./MainHeader.module.css";

const MainHeader: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });
  let navigate = useNavigate();

  const navigateToScience = () => {
    navigate('science/');
  }

  return (
    <div className={styles.headerMain} ref={ref}>
      <motion.div
        className={styles.headerOpening}
        variants={{
          hidden: { opacity: 0, x: -400 },
          visible: { opacity: 1, x: 0 },
        }}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        transition={{ duration: 0.75, delay: 0.15 }}
      >
        <h1 style={{ maxWidth: "18ch", fontSize: 50 }}>
          The Future of Immersive Learning
        </h1>
        <h3 style={{ maxWidth: "30ch", fontSize: 25 }}>
          We aim to use virtual and augmented reality to make experiential learning
          accessible to all high school students.
        </h3>
        <SliderButton title="Learn More" onPress={navigateToScience} />
      </motion.div>
      <Tilt>
        <motion.div
          variants={{
            hidden: { opacity: 0, x: 400 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          transition={{ duration: 0.75, delay: 0.15 }}
        >
          <img src={vr} alt="SageTech VR" className={styles.vrImg} />
        </motion.div>
      </Tilt>
    </div>
  );
};

export default MainHeader;
