import React from "react";
import styles from "./SliderButton.module.css";

interface SliderButtonProps {
    title: string;
    onPress: any;
}

const SliderButton: React.FC<SliderButtonProps> = ({ title, onPress }) => {
  return (
    <div className={styles.mainContainer}>
      <button className={styles.arrowContainer} onClick={onPress}>
        <i className="uil uil-angle-right"></i>
      </button>
      <div className={styles.sliderText}>
        <span>{title}</span>
      </div>
    </div>
  );
};

export default SliderButton;
