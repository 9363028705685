import React, { useRef } from "react";
import { useNavigate } from 'react-router';
import { motion, useInView } from "framer-motion";

import styles from "./GlassCardContainer.module.css";
import SliderButton from "./SliderButton";

const GlassCardContainer: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });
  const navigate = useNavigate();

  const navigateToMetaweaver = () => {
    navigate('metaweaver/');
  }

  const navigateToSage = () => {
    navigate('sage/');
  }

  return (
    <div className={styles.cardsContainer} ref={ref}>
      <motion.div
        className={styles.glassCard}
        variants={{
          hidden: { opacity: 0, y: 400 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        transition={{ duration: 0.75, delay: 0.1 }}
      >
        <h2>MetaWeaver</h2>
        <p className={styles.glassCardText}>
          Our software for building beautiful virtual worlds.
        </p>
        <ul className={styles.glassCardUL}>
          <li>
            Use hand gestures, voice commands, and eye movement to build digital
            worlds rapidly
          </li>
          <li>Build and test virtual learning environments</li>
          <li>Intuitive, simple interface for effortless content creation</li>
        </ul>
        <SliderButton title="Learn More" onPress={navigateToMetaweaver} />
      </motion.div>
      <motion.div
        className={styles.glassCard}
        variants={{
          hidden: { opacity: 0, y: 400 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        transition={{ duration: 0.75, delay: 0.45 }}
      >
        <h2>Sage</h2>
        <p className={styles.glassCardText}>
          Our platform for sharing and exploring virtual reality environments.
        </p>
        <ul className={styles.glassCardUL}>
          <li>Access and learn experientially from virtual environments</li>
          <li>Upload lessons in the form of digital worlds</li>
          <li>Browse and explore other creator’s lessons</li>
        </ul>
        <SliderButton title="Learn More" onPress={navigateToSage} />
      </motion.div>
      <motion.div
        className={styles.glassCard}
        variants={{
          hidden: { opacity: 0, y: 400 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        transition={{ duration: 0.75, delay: 0.75 }}
      >
        <h2>Companion App</h2>
        <p className={styles.glassCardText}>
          Take your analysis of student performance to the next level.
        </p>
        <ul className={styles.glassCardUL}>
          <li>Stay up-to-date with student scores and performance</li>
          <li>Track student’s attention while they are learning</li>
          <li>Discover areas of weakness in our student analytics suite</li>
        </ul>
        <SliderButton title="Coming Soon" onPress={() => {}} />
      </motion.div>
    </div>
  );
};

export default GlassCardContainer;
