import React from "react";
import styles from "./SocialMediaButton.module.css";

interface SocialMediaButtonProps {
  children: string | JSX.Element;
  onPress: any;
}

const SocialMediaButton: React.FC<SocialMediaButtonProps> = ({
  children,
  onPress,
}) => {
  return (
    <button onClick={onPress} className={styles.socialMediaButton}>
      {children}
    </button>
  );
};

export default SocialMediaButton;
