import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";

import styles from "./NavigationBar.module.css";

interface NavBarProps {
  activePath: string;
}

interface CustomLinkProps {
  activePath: string;
  title: string;
  path: string;
}

const CustomLink: React.FC<CustomLinkProps> = ({ activePath, title, path }) => {
  if (activePath === path) {
    return (
      <li className={styles.navList}>
        <Link to={path} style={{ color: "#fff", textDecoration: 'none' }} className={styles.activeLink}>
          {title}
        </Link>
      </li>
    );
  } else {
    return (
      <Link to={path} style={{ color: "#fff", width: "80%", textDecoration: 'none' }}>
        <li className={styles.navList}>{title}</li>
      </Link>
    );
  }
};

const NavigationBar: React.FC<NavBarProps> = ({ activePath }) => {
  const [showBurgerMenu, setBurgerMenu] = useState(false);

  const burgerMenuHandler = () => {
    setBurgerMenu(!showBurgerMenu);
  };
  console.log(showBurgerMenu);

  return (
    <>
      <nav className={styles.mainNavContainer}>
        <img className={styles.logoImg} src={logo} alt="SageTech" />
        <ul className={styles.removeDefaultStyle}>
          <CustomLink title="Home" path="/" activePath={activePath} />
          <CustomLink
            title="MetaWeaver"
            path="/metaweaver"
            activePath={activePath}
          />
          <CustomLink title="Sage" path="/sage" activePath={activePath} />
          <CustomLink title="Science" path="/science" activePath={activePath} />
          <CustomLink title="About" path="/about" activePath={activePath} />
        </ul>
        <div className={styles.mobileNavBarContainer}>
          <button className={styles.burgerButton} onClick={burgerMenuHandler}>
            {!showBurgerMenu ? (
              <i className="uil uil-bars"></i>
            ) : (
              <i className="uil uil-multiply"></i>
            )}
          </button>
        </div>
      </nav>
      {showBurgerMenu && (
        <motion.div
          style={{ display: "flex", justifyContent: "center" }}
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -100 }}
        >
          <ul className={styles.burgerMenuUl}>
            <CustomLink title="Home" path="/" activePath={activePath} />
            <CustomLink
              title="MetaWeaver"
              path="/metaweaver"
              activePath={activePath}
            />
            <CustomLink title="Sage" path="/sage" activePath={activePath} />
            <CustomLink
              title="Science"
              path="/science"
              activePath={activePath}
            />
            <CustomLink title="About" path="/about" activePath={activePath} />
          </ul>
        </motion.div>
      )}
    </>
  );
};

export default NavigationBar;