import React from "react";

interface LearningFactProps {
    text: string;
    size: number;
}

const LearningFact: React.FC<LearningFactProps> = ({ text, size }) => {
    return (
        <div style={{fontSize: size, marginBottom: 20}}>
            {text}
        </div>
    )
}

export default LearningFact;