import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import Footer from "../components/UI/Footer";
import SlideInCards from "../components/SlideInCards";
import metavideo from "../assets/finalmeta.mp4";
import gridone from "../assets/gridone.png";
import SageScience5 from "../assets/SageScience5.jpeg";
import styles from "./SageScreen.module.css";
import trying2 from "../assets/trying2.jpeg"
import trying3 from "../assets/trying3.jpeg"
interface SageScreenProps {
  activePath: any;
}

interface GlassContainerProps {
  title: string;
  text: string;
  image: string;
  flex?: "row" | "row-reverse" | "column" | "column-reverse";
}

const sageContent: GlassContainerProps[] = [
  {
    title: "Making Experiential Learning Accessible",
    text: "Recognizing the profound impact of experiential learning, we are committed to enhancing students’ educational journeys by fostering more enjoyable and effective teaching methods. Sage empowers educators worldwide to contribute immersive experiences across diverse subjects, cultivating an extensive repository of captivating and innovative learning materials accessible to students from anywhere.",
    image: gridone,
    flex: "row",
  },
  {
    title: "Unique experiences to transport students beyond the classroom.",
    text: "Sage lets students explore unique digital worlds that unlock limitless educational possibilities. Imagine delving into the world of cellular biology, witnessing the marvels of cellular division unfold in real-time from within the mitochondria. Envision stepping into Leonardo da Vinci’s studio during the Renaissance, or watching the historical signing of the Declaration of Independence over Thomas Jefferson’s shoulder. Picture comprehending complex physics concepts through visualizations showing the connection between equations with physical phenomena. Immerse yourself in the literary works of Hamlet or The Odyssey, seamlessly transitioning from reading the stories to actively experiencing them firsthand. Walk through any historical period, experience natural phenomena at any scale – if you can imagine it, you can experience it with Sage",
    image:
      SageScience5,
    flex: "row-reverse",
  },
];

const SageScreen: React.FC<SageScreenProps> = ({ activePath }) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const firstRender = useRef(true);
  const location = useLocation();

  useEffect(() => {
    activePath(String(location.pathname));
    firstRender.current = false;
  }, [location.pathname, activePath]);

  return (
    <>
      <div className={styles.mainContainer}>
        <video
          className={styles.bgVideo}
          width="100%"
          src={metavideo}
          autoPlay
          loop
          muted
        >
          <source src={metavideo} type="video/mp4" />
        </video>
        <div style={{ marginTop: 130 }}>&nbsp;</div>
        <motion.div
          className={styles.openingLineContainer}
          variants={{
            hidden: { opacity: 0, scale: 0, y: -100 },
            visible: { opacity: 1, scale: 1, y: 0 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 1.25 }}
        >
          <h1 className={styles.headerTitle}>Sage</h1>
          <p className={styles.openingLine}>
            Sage is our platform for hosting and sharing virtual reality
            learning content.
          </p>
          <p className={styles.openingLine}>
            Sage leverages virtual reality to empower students to exceed the
            limitations of traditional learning methods.
            <b> Experience the future of education with Sage</b>, where knowledge
            knows no boundaries.
          </p>
        </motion.div>
        {sageContent.map((item) => (
          <SlideInCards
            title={item.title}
            text={item.text}
            image={item.image}
            flex={windowSize.current[0] < 721 ? "column" : item.flex}
          />
        ))}
      </div>
      <Footer />
    </>
  );
};

export default SageScreen;
