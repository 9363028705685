import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./AboutScreen.module.css";
import Olivier from "../assets/olivier.jpeg";
import AvatarContainer from "../components/AvatarContainer";
import storyPic from "../assets/ourStory.png";
import Footer from "../components/UI/Footer";
import { motion, useInView } from "framer-motion";
import Breezy from "../assets/breezy.jpeg";
import Cmoney from "../assets/cmoney.jpeg";
import DaSHIIIIT from "../assets/DaShit.jpeg";
import grootimcfrooti from "../assets/grooti.jpg"

interface AboutScreenProps {
  activePath: any;
}
interface AvatarContainerProps {
  name: string;
  description: string;
  image: any;
  flex: "row" | "row-reverse" | "column" | "column-reverse";
  textAlign: "left" | "right";
}
//an array of objects that has to have a name, description, and image
const members: AvatarContainerProps[] = [
  {
    name: "Claudia Santacruz",
    description:
      "Claudia is a Rutgers Honors Student Majoring in Cognitive Science as well as IT and informatics. She helps lead the team on formal research to support the company’s foundation and manages agile development. Claudia remains at the forefront of the emerging XR industry and currently works as an intern/co-op at Johnson & Johnson in the MedTech department, working on the implementation of XR for company solutions, marketing, and education. She possesses the skills and mindset of an innovator in her field with active membership in the innovation and entrepreneurial ecosystem at Rutgers University.",
    image: Cmoney,
    flex: "row-reverse",
    textAlign: "left",
  },
  {
    name: "Rut Mehta",
    description:
      "Rut is currently pursuing a double major in Business Analytics, Information Technology and Computer Science with a minor in Mathematics. A problem-solver and thinker at heart, Rut is always looking for innovative solutions to worldwide problems and looking at the next best in the tech world. Rut also runs a design-thinking studio on campus, and is publishing research papers on neuroscience and experiential learning at Rutgers University. He is also a long-standing member of the I.D.E.A. Program at Rutgers, and is currently working on developing techniques to mitigate backdoor adversarial attacks in audio/visual extended deep neural network pipelines applied to interferences in CSI channels.",
    image: grootimcfrooti,
    flex: "row",
    textAlign: "right",
  },
  {
    name: "Brianna Lischy",
    description:
      "Brianna is studying Business Analytics and Information Technologies with a strong focus on entrepreneurship. She has successfully run pet care and custom art commission businesses for five years, leading her to seek a career in sales or global business. She is also a part of the Interdisciplinary Research Team at Rutgers exploring neuroscience and Edtech integration. Brianna demonstrates strong interpersonal and leadership skills through her involvement in the Innovation, Design, Entrepreneurship Academy (I.D.E.A.), and other extracurriculars.",
    image: Breezy,
    flex: "row",
    textAlign: "right",
  },
  {
    name: "Oliver Kisza",
    description:
      "Oliver currently works at a proprietary trading firm designing, developing and deploying algorithmic trading strategies in capital markets. He is pursuing a degree in Finance and Statistics at Rutgers University. Oliver provides financial consulting services to clients regarding estate and retirement planning, monthly budgeting, and tax efficiency. He enjoys playing tennis in his spare time, having spent more than six years coaching children and adults of all ages to prepare for and succeed at state and national level tournaments. Oliver is passionate about unique opportunities for improving the lives of many people, especially through innovations in new technologies.",
    image: Olivier,
    flex: "row",
    textAlign: "right",
  },
  {
    name: "Deshik Iyengar",
    description:
      "Deshik is currently pursuing a double major in linguistics and French with a minor in philosophy. He is a member of the I.D.E.A. program at Rutgers University and has participated in a summer internship with fellow students to help promote neurodiversity at Rutgers. Deshik is the secretary of the Linguistics Club and is conducting research with the Linguistics faculty about language acquisition in children. In his spare time, Deshik enjoys reading, watching tennis, and creative writing.",
    image: DaSHIIIIT,
    flex: "row-reverse",
    textAlign: "left",
  },
];

const AboutScreen: React.FC<AboutScreenProps> = ({ activePath }) => {
  const firstRender = useRef<boolean>(true);
  const location = useLocation();
  const headerRef = useRef<HTMLDivElement>(null);
  const isInViewHeaderRef = useInView(headerRef, { once: true });
  const storyRef = useRef<HTMLDivElement>(null);
  const isInViewStoryRef = useInView(storyRef, { once: true });
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    activePath(String(location.pathname));
    firstRender.current = false;
  }, [location.pathname, activePath]);

  return (
    <>
      <div className={styles.mainBackground} >
        <motion.div ref={headerRef}
          className={styles.main}
          variants={{
            hidden: { opacity: 0, x: windowSize.current[0] > 721 ? -100 : 0, scale: 0 },
            visible: { opacity: 1, x: 0, scale: 1 },
          }}
          initial="hidden"
          animate={isInViewHeaderRef ? "visible" : "hidden"}
          transition={{ duration: 1.249, delay: 0.1 }}
        >
          {/* container for everything in about page*/}
          <div className={styles.missionContainer}>
            {/*this stores the mission statement*/}
            <div className={styles.missionHeader}>
              <b>Our Mission and Vision</b>
            </div>
            <div className={styles.missionContent}>
              <br></br>
              Our mission is to <b className={styles.boldWord}> revolutionize </b>
              the way educational content is delivered.
              <br></br>
              <br></br>
              We envision a world with immersive,
              <b className={styles.boldWord}> engaging</b>, and
              <b className={styles.boldWord}> effective</b> experiential
              education.
            </div>
          </div>
        </motion.div>

        <div className={styles.storyContainer} >
          {/*This is the container for the story section */}

          <motion.div className={styles.cardRow} ref={storyRef} variants={{
            hidden: {scale: 0 },
            visible: {scale: 1 },
          }}
          initial="hidden"
          animate={isInViewStoryRef ? "visible" : "hidden"}
          transition={{ duration: 1.5, delay: 0.1 }}>
            <div className={styles.infoCard} id = "мамичка">
              <div className={styles.storyHeader}>
                <b>Our Story</b>
              </div>
              <p>
                Founded in October 2021, SageTech emerged with a clear purpose:
                to enhance and democratize experiential learning in K-12
                schools. Initially consisting of six founding members, our team
                shared a deep motivation to improve the learning outcomes of
                students in New Jersey.
              </p>
              <p>
                Driven by our commitment to excellence, SageTech actively
                engages in research focused on active multi-sensory student
                learning. We are currently collaborating with Rutgers
                University’s Innovation, Design, and Entrepreneurship Academy
                (IDEA) program to push the boundaries of educational innovation.
              </p>
              <p>
                In April 2023, we solidified a partnership with researchers from
                the New Jersey Institute of Technology (NJIT). Together, we
                embarked on groundbreaking research to validate the
                effectiveness of virtual reality (VR) learning in K-12 STEM
                subjects. This collaboration highlights our dedication to
                leveraging cutting-edge technology to revolutionize education.
              </p>
              <p>
                Our team comprises a diverse group of passionate college
                students working in tandem with industry professionals. We
                harness the power of virtual reality and cognitive science to
                drive meaningful improvements in education systems worldwide.
                Our ultimate ambition is to make a lasting positive impact on
                the education industry by fundamentally transforming how humans
                engage with the process of learning.
              </p>
            </div>
            <div className={styles.imageContainer}>
              <img
                src={storyPic}
                alt="SageTech Group"
                className={styles.image}
              />
            </div>
          </motion.div>
        </div>
      </div>
      <div className={styles.mainTeamContainer}>
        <h3 className={styles.teamHeader}>Meet The Team</h3>
        {/* user is the variable for the object propery you want. map is just iterating through the array */}
        {/* map made it easier for us to get the values by looping through the arrays we want from the library */}
        {members.map((user) => (
          <AvatarContainer
            name={user.name}
            description={user.description}
            image={user.image}
            flex={user.flex}
            textAlign={user.textAlign}
          />
        ))}
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </>
  );
};

export default AboutScreen;
