import React, { useRef} from "react";
import styles from "./AvatarContainer.module.css";
import { motion, useInView } from "framer-motion";


//interface will be giving the types of the props
interface AvatarContainerProps {
  name: string;
  description: string;
  image: any;
  flex: "row" | "row-reverse" | "column" | "column-reverse";
  textAlign: "left" | "right";
}

// AvatarContainer represents the function I'm making
// React.FC is what I am returning. I am returning a react component.
// the () for the arguments/parameters, which would usually be props.
//This is going to return a react component containing the prop elements.
//props is essentially like an argument/parameter you put into a function.
const AvatarContainer: React.FC<AvatarContainerProps> = ({name, description, image, flex, textAlign}) => {
  const avatarRef = useRef<HTMLDivElement>(null);
  const isInViewAvatarRef = useInView(avatarRef, { once: true });
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  return (
    <motion.div className={styles.avatarVContainer} style = {{flexDirection: flex, textAlign: textAlign}} ref = {avatarRef} 
    variants={{
      hidden: { opacity: 0,  x: windowSize.current[0] > 721 ? -500 : 0},
      visible: {opacity: 1, x: 0},
    }}
    initial="hidden"
    animate={isInViewAvatarRef ? "visible" : "hidden"}
    transition={{ duration: 1.25, delay: 0.1 }}>
      <div className={styles.avatarTextContainer}>
        <h1 className={styles.avatarNameHeader}>{name}</h1>
        <p className={styles.avatarText}>
          {description}
        </p>
      </div>
      <div className={styles.avatarImageContainer}>
        <img src={image} alt={name} className={styles.avatarImage} />
      </div>
    </motion.div>
  );
};

export default AvatarContainer;
