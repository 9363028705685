import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import HomeScreen from "./screens/HomeScreen";
import MetaWeaverScreen from "./screens/MetaWeaver";
import SageScreen from "./screens/SageScreen";
import ScienceScreen from "./screens/ScienceScreen";
import AboutScreen from "./screens/AboutScreen";
import NavigationBar from "./components/UI/NavigationBar";

const App: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("test");

  const activeTabHandler = (path: string) => {
    setActiveTab(path);
  }
  
  return (
    <div className="App">
      <BrowserRouter>
        <NavigationBar activePath={activeTab}/>
        <Routes>
          <Route path="/" element={<HomeScreen activePath={activeTabHandler} />} />
          <Route path="metaweaver/" element={<MetaWeaverScreen activePath={activeTabHandler} />} />
          <Route path="sage/" element={<SageScreen activePath={activeTabHandler} />} />
          <Route path="science/" element={<ScienceScreen activePath={activeTabHandler} />} />
          <Route path="about/" element={<AboutScreen activePath={activeTabHandler} />} />
          <Route path = "edtech/" element={<HomeScreen activePath={activeTabHandler} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
